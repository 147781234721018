
import { defineComponent } from "vue";
import Phone from "@/components/reusable/Phone.vue";
import Laptop from "@/components/reusable/Laptop.vue";
import Jewelry from "@/components/reusable/Jewelry.vue";
import Camera from "@/components/reusable/Camera.vue";
import MusicalInstrument from "@/components/reusable/MusicalInstrument.vue";
import Inverter from "@/components/reusable/Inverter.vue";

import EditPhone from "@/components/reusable/EditPhone.vue";
import EditLaptop from "@/components/reusable/EditLaptop.vue";
import EditJewelry from "@/components/reusable/EditJewelry.vue";
import EditCamera from "@/components/reusable/EditCamera.vue";
import EditMusicalInstrument from "@/components/reusable/EditMusicalInstrument.vue";
import EditInverter from "@/components/reusable/EditInverter.vue";

export default defineComponent({
  name: "house-insurance-contents-edit-component",
  components: {
    Phone,
    Laptop,
    Jewelry,
    Camera,
    MusicalInstrument,
    Inverter,
    EditPhone,
    EditLaptop,
    EditJewelry,
    EditCamera,
    EditMusicalInstrument,
    EditInverter
  },
  props: {
    phoneRate: Number,
    laptopRate: Number,
    cameraRate: Number,
    inverterRate: Number,
    musicalInstrumentRate: Number,
    jewelryRate: Number,
    invoiceSummary: Object,
    grandPremium: Number
  },
  methods: {
    hasItems: function (content) {
      if (!content) {
        return false;
      }
      return content.length > 0;
    }
  }
  // created() {
  //   console.log(this.invoiceSummary);
  // }
});
