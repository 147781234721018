
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import variables from "@/router/api";
import $ from "jquery";

export default defineComponent({
  name: "edit-phone-content-component",
  components: { Field, ErrorMessage },
  props: {
    phoneRate: Number,
    invoiceSummary: Object,
    grandPremium: Number,
  },
  data() {
    return {
      phoneDetails: [
        {
          serialNumber: "",
          imageOfReceipt: "",
          value: 0.0 as number,
        },
      ],
      totalPremium: 0.0 as number,
    };
  },
  created() {
    setTimeout(() => {
      this.phoneDetails = this.invoiceSummary as any;
      this.totalPremium = this.grandPremium as number;
    }, 1500);
  },
  methods: {
    premium(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);
      // const value = variables.removeComma(event.target.value);

      // Check if value is a number
      if (isNaN(value) == false) {
        // Calculate premium from vehicle value
        variables.premiumCalculator(
          event.target.ariaValueNow,
          value,
          event.target.ariaValueText
        );
      } else {
        return;
      }

      // Append total premium value tot total premium field
      this.totalPremium = variables.totalPremiumValue();
    },
    addDynamicField() {
      this.phoneDetails.push({
        serialNumber: "",
        imageOfReceipt: "",
        value: 0.0 as number,
      });
    },
    removeDynamicField(counter) {
      // Remove dynamic field
      $(".remove-phone-" + counter).remove();

      setTimeout(() => {
        // Update total premium
        this.totalPremium = variables.totalPremiumValue();
      }, 200);
    },
    phoneReceiptSelected(event, position) {
      this.phoneDetails[position].imageOfReceipt = event.target.files;
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
  },
});
