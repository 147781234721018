import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row col-md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditPhone = _resolveComponent("EditPhone")!
  const _component_Phone = _resolveComponent("Phone")!
  const _component_EditLaptop = _resolveComponent("EditLaptop")!
  const _component_Laptop = _resolveComponent("Laptop")!
  const _component_EditJewelry = _resolveComponent("EditJewelry")!
  const _component_Jewelry = _resolveComponent("Jewelry")!
  const _component_EditCamera = _resolveComponent("EditCamera")!
  const _component_Camera = _resolveComponent("Camera")!
  const _component_EditMusicalInstrument = _resolveComponent("EditMusicalInstrument")!
  const _component_MusicalInstrument = _resolveComponent("MusicalInstrument")!
  const _component_EditInverter = _resolveComponent("EditInverter")!
  const _component_Inverter = _resolveComponent("Inverter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasItems(_ctx.invoiceSummary.phoneDetails))
      ? (_openBlock(), _createBlock(_component_EditPhone, {
          key: 0,
          "phone-rate": _ctx.phoneRate,
          "invoice-summary": _ctx.invoiceSummary ? _ctx.invoiceSummary.phoneDetails : '',
          "grand-premium": _ctx.totalPremium
        }, null, 8, ["phone-rate", "invoice-summary", "grand-premium"]))
      : (_openBlock(), _createBlock(_component_Phone, {
          key: 1,
          "phone-rate": _ctx.phoneRate
        }, null, 8, ["phone-rate"])),
    (_ctx.hasItems(_ctx.invoiceSummary.laptopOrTabletDetails))
      ? (_openBlock(), _createBlock(_component_EditLaptop, {
          key: 2,
          "laptop-rate": _ctx.laptopRate,
          "invoice-summary": 
        _ctx.invoiceSummary ? _ctx.invoiceSummary.laptopOrTabletDetails : ''
      ,
          "grand-premium": _ctx.totalPremium
        }, null, 8, ["laptop-rate", "invoice-summary", "grand-premium"]))
      : (_openBlock(), _createBlock(_component_Laptop, {
          key: 3,
          "laptop-rate": _ctx.laptopRate
        }, null, 8, ["laptop-rate"])),
    (_ctx.hasItems(_ctx.invoiceSummary.jewelryDetails))
      ? (_openBlock(), _createBlock(_component_EditJewelry, {
          key: 4,
          "jewelry-rate": _ctx.jewelryRate,
          "invoice-summary": _ctx.invoiceSummary ? _ctx.invoiceSummary.jewelryDetails : '',
          "grand-premium": _ctx.totalPremium
        }, null, 8, ["jewelry-rate", "invoice-summary", "grand-premium"]))
      : (_openBlock(), _createBlock(_component_Jewelry, {
          key: 5,
          "jewelry-rate": _ctx.jewelryRate
        }, null, 8, ["jewelry-rate"])),
    (_ctx.hasItems(_ctx.invoiceSummary.cameraDetails))
      ? (_openBlock(), _createBlock(_component_EditCamera, {
          key: 6,
          "camera-rate": _ctx.cameraRate,
          "invoice-summary": _ctx.invoiceSummary ? _ctx.invoiceSummary.cameraDetails : '',
          "grand-premium": _ctx.totalPremium
        }, null, 8, ["camera-rate", "invoice-summary", "grand-premium"]))
      : (_openBlock(), _createBlock(_component_Camera, {
          key: 7,
          "camera-rate": _ctx.cameraRate
        }, null, 8, ["camera-rate"])),
    (_ctx.hasItems(_ctx.invoiceSummary.musicalInstrumentDetails))
      ? (_openBlock(), _createBlock(_component_EditMusicalInstrument, {
          key: 8,
          "musical-instrument-rate": _ctx.musicalInstrumentRate,
          "invoice-summary": 
        _ctx.invoiceSummary ? _ctx.invoiceSummary.musicalInstrumentDetails : ''
      ,
          "grand-premium": _ctx.totalPremium
        }, null, 8, ["musical-instrument-rate", "invoice-summary", "grand-premium"]))
      : (_openBlock(), _createBlock(_component_MusicalInstrument, {
          key: 9,
          "musical-instrument-rate": _ctx.musicalInstrumentRate
        }, null, 8, ["musical-instrument-rate"])),
    (_ctx.hasItems(_ctx.invoiceSummary.inverterDetails))
      ? (_openBlock(), _createBlock(_component_EditInverter, {
          key: 10,
          "inverter-rate": _ctx.inverterRate,
          "invoice-summary": _ctx.invoiceSummary ? _ctx.invoiceSummary.inverterDetails : '',
          "grand-premium": _ctx.totalPremium
        }, null, 8, ["inverter-rate", "invoice-summary", "grand-premium"]))
      : (_openBlock(), _createBlock(_component_Inverter, {
          key: 11,
          "inverter-rate": _ctx.inverterRate
        }, null, 8, ["inverter-rate"]))
  ]))
}